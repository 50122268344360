import { ReactNode, ComponentProps, Fragment } from 'react'

import { Spacer } from '@vinted/web-ui'

import PageTitleWithImage from 'components/PageTitleWithImage'

import GridItem from './GridItem'

import styles from './IllustratedTextGrid.module.css'

type ItemProps = ComponentProps<typeof GridItem>
type TitleProps = ComponentProps<typeof PageTitleWithImage>

type Props = {
  desktopSeparators?: Array<ReactNode>
  title?: TitleProps
  imageLoadingStrategy?: ItemProps['imageLoadingStrategy']
  items: Array<Omit<ItemProps, 'imageLoadingStrategy'>>
}

const IllustratedTextGrid = ({ items, title, desktopSeparators, imageLoadingStrategy }: Props) => {
  const getDesktopSeparatorForItem = (itemIndex: number) => {
    if (!desktopSeparators?.length) return null

    return desktopSeparators[itemIndex] || null
  }

  return (
    <div className={styles.illustratedTextGridContainer}>
      {title ? <PageTitleWithImage {...title} /> : null}

      {items.map((item, index) => {
        const isLastItem = index === items.length - 1

        return (
          <Fragment key={`${item.title || ''}-${index}`}>
            <GridItem {...item} imageLoadingStrategy={imageLoadingStrategy} />
            {isLastItem ? null : (
              <div className={styles.mobileSeparator}>
                <Spacer size={Spacer.Size.X3Large} />
              </div>
            )}
            <div className={styles.desktopSeparator}>{getDesktopSeparatorForItem(index)} </div>
          </Fragment>
        )
      })}
    </div>
  )
}

export default IllustratedTextGrid
