import { useTranslation } from 'next-i18next'

import { Spacer } from '@vinted/web-ui'

import IllustratedTextGrid from 'components/IllustratedTextGrid'

import styles from './GeneralSection.module.css'

const GeneralSection = () => {
  const { t } = useTranslation('common', { keyPrefix: 'main.general' })

  return (
    <div className={styles.wrapper}>
      <Spacer size={Spacer.Size.X4Large} />
      <div className={styles.content}>
        <IllustratedTextGrid
          items={[
            {
              title: t('title'),
              description: t('description'),
              imageSrc: '/assets/items.png',
            },
          ]}
        />
      </div>
      <Spacer size={Spacer.Size.X4Large} />
    </div>
  )
}

export default GeneralSection
