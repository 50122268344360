import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { Text, Spacer } from '@vinted/web-ui'

import { formatMoney } from 'lib/utils/currency'
import { useSelectedShippingRoute } from 'lib/context/shippingRoute'

import Proposition from './Proposition'

import styles from './ValuePropositionSection.module.css'

const ValuePropositionSection = () => {
  const { locale } = useRouter()
  const { t } = useTranslation('common', { keyPrefix: 'main.valueProposition' })

  const { selectedRoute } = useSelectedShippingRoute()

  const standardCoverageLimit = selectedRoute
    ? formatMoney(selectedRoute.coverage.standardCoverageLimit, locale, {
        keepFractionDigits: false,
      })
    : null
  const extraCoverageLimit = selectedRoute
    ? formatMoney(selectedRoute.coverage.extraCoverageLimit, locale, {
        keepFractionDigits: false,
      })
    : null

  return (
    <div className={styles.wrapper}>
      <Spacer size={Spacer.Size.X4Large} />
      <div className={styles.content}>
        <Text
          text={t('title')}
          width={Text.Width.Parent}
          type={Text.Type.HeadingXXL}
          alignment={Text.Alignment.Center}
        />
        <Spacer size={Spacer.Size.X4Large} />

        <div className={styles.propositions}>
          <Proposition
            src="coins.svg"
            title={`${t('rates.title')}`}
            description={`${t('rates.description')}`}
          />
          <Proposition
            src="umbrella.svg"
            title={t('coverage.title', {
              coverageLimit: standardCoverageLimit,
            })}
            description={t('coverage.description', {
              standardCoverageLimit,
              extraCoverageLimit,
            })}
          />
          <Proposition
            src="further.svg"
            title={`${t('locations.title')}`}
            description={`${t('locations.description')}`}
          />
          <Proposition
            src="simpler.svg"
            title={`${t('noRegistration.title')}`}
            description={`${t('noRegistration.description')}`}
          />
          <Proposition
            src="greener.svg"
            title={`${t('climateImpact.title')}`}
            description={`${t('climateImpact.description')}`}
          />
        </div>
      </div>
      <Spacer size={Spacer.Size.X4Large} />
    </div>
  )
}

export default ValuePropositionSection
