import Image from 'next/image'

import { Cell } from '@vinted/web-ui'

import { getStaticAssetUrl } from 'lib/utils/image'

import ShippingPriceCalculator from './ShippingPriceCalculator'

import styles from './ShippingCTASection.module.css'

const ILLUSTRATION_URL = getStaticAssetUrl('routes-hero-image-v9.avif')

const ShippingCTASection = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Cell theme="transparent">
          <div className={styles.calculatorAndHeroImageContainer}>
            <ShippingPriceCalculator />
            <div className={styles.heroImage}>
              <Image fill priority alt="" src={ILLUSTRATION_URL} style={{ objectFit: 'fill' }} />
            </div>
          </div>
        </Cell>
      </div>
    </div>
  )
}

export default ShippingCTASection
