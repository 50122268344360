import Image from 'next/image'

import { getStaticAssetUrl } from 'lib/utils/image'

import styles from './PartnerLogo.module.css'

const PARTNER_IMAGE_PATH_PREFIX = 'trusted-partners'

type ImageProps = ComponentProps<typeof Image>

type Props = {
  src: `${string}.svg`
  alt: ImageProps['alt']
  width: ImageProps['width']
  height: ImageProps['height']
}

const PartnerLogo = ({ src, alt, width, height }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Image
        alt={alt}
        width={width}
        height={height}
        loading="lazy"
        style={{ objectFit: 'contain' }}
        src={getStaticAssetUrl(`${PARTNER_IMAGE_PATH_PREFIX}/${src}`)}
      />
    </div>
  )
}

export default PartnerLogo
