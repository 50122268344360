import { RouteModel, RoutesModel } from 'lib/types/models/route'
import { ShippingRouteProvider } from 'lib/context/shippingRoute'
import { usePageVisitTracking } from 'lib/hooks/tracking/usePageVisitTracking'

import HelpSection from './HelpSection'
import GeneralSection from './GeneralSection'
import TrackingSection from './TrackingSection'
import ShippingCTASection from './ShippingCTASection'
import NetworkNumbersSection from './NetworkNumbersSection'
import TrustedPartnersSection from './TrustedPartnersSection'
import ValuePropositionSection from './ValuePropositionSection'
import PriceAndSustainabilitySection from './PriceAndSustainabilitySection'

type Props = {
  routes: RoutesModel | null
  selectedRoute: RouteModel | null
}

const MainPage = ({ routes, selectedRoute }: Props) => {
  usePageVisitTracking({ event: 'visit_landing_page' })

  return (
    <ShippingRouteProvider routes={routes} initialSelectedRoute={selectedRoute}>
      <ShippingCTASection />
      <TrustedPartnersSection />
      <ValuePropositionSection />
      <GeneralSection />
      <NetworkNumbersSection />
      <TrackingSection />
      <HelpSection />
      <PriceAndSustainabilitySection />
    </ShippingRouteProvider>
  )
}

export default MainPage
