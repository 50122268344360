import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import { Text, Spacer, Divider } from '@vinted/web-ui'

import styles from './NumberCountUp.module.css'

const COUNT_UP_DURATION = 1500

const NumberCountUp = ({
  caption,
  finalNumber,
  startCountUp,
  postfix = '',
}: {
  postfix?: string | null
  caption: string
  finalNumber: number
  startCountUp: boolean
}) => {
  const { locale } = useRouter()
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (!startCountUp) return

    let startTime = 0

    const startCounting = (timestamp: number) => {
      if (!startTime) {
        startTime = timestamp
      }

      const elapsedTime = timestamp - startTime
      const progress = elapsedTime / COUNT_UP_DURATION

      if (progress < 1) {
        const newCount = Math.floor(finalNumber * progress)
        setCount(newCount)
        requestAnimationFrame(startCounting)
      } else {
        setCount(finalNumber)
      }
    }

    requestAnimationFrame(startCounting)
  }, [finalNumber, startCountUp])

  const formattedNumber = new Intl.NumberFormat(locale).format(count)
  const formattedNumberWithSign = `${formattedNumber}+`

  // Additional container to ensure that postfix does not jump during count up.
  const valueClassNames = classNames({
    [styles.valueWithPostfix]: !!postfix,
  })

  return (
    <div className={styles.wrapper}>
      <div className={styles.valueRow}>
        <div className={valueClassNames}>
          <Text text={formattedNumberWithSign} type={Text.Type.HeadingXXL} theme="inverse" />
        </div>
        {postfix ? (
          <div className={styles.postfix}>
            <Text text={postfix} type={Text.Type.HeadingXXL} theme="inverse" />
          </div>
        ) : null}
      </div>
      <Spacer />
      <Divider inverse />
      <Spacer />
      <Text text={caption} type={Text.Type.Caption} theme="inverse" />
      <Spacer size={Spacer.Size.Medium} />
    </div>
  )
}

export default NumberCountUp
