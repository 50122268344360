import { useTranslation } from 'next-i18next'

import { Spacer } from '@vinted/web-ui'

import IllustratedTextGrid from 'components/IllustratedTextGrid'
import useVintedGoCorporatePageUrl from 'lib/hooks/useVintedGoCorporatePageUrl'

import styles from './TrackingSection.module.css'

const TrackingSection = () => {
  const { t } = useTranslation('common', { keyPrefix: 'main.tracking' })
  const trackingPageUrl = useVintedGoCorporatePageUrl('/tracking/routes')

  return (
    <div className={styles.wrapper}>
      <Spacer size={Spacer.Size.X4Large} />
      <div className={styles.content}>
        <IllustratedTextGrid
          items={[
            {
              isTextOnTheRight: true,
              title: t('title'),
              description: t('description'),
              imageSrc: '/assets/tracking.png',
              action: {
                title: t('action'),
                href: trackingPageUrl,
              },
            },
          ]}
        />
      </div>
      <Spacer size={Spacer.Size.X4Large} />
    </div>
  )
}

export default TrackingSection
