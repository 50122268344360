import { useState } from 'react'
import { useTranslation } from 'next-i18next'

import { Text, Spacer } from '@vinted/web-ui'

import { SupportedCountry } from 'lib/types/country'
import { findRouteIn, getFirstRouteFrom } from 'lib/utils/route'
import { useSelectedShippingRoute } from 'lib/context/shippingRoute'
import { useTranslatedShippingRoutes } from 'lib/hooks/useTranslatedShippingRoutes'

import BuyLabelButton from 'components/BuyLabelButton'
import DropdownSelect from 'components/DropdownSelect'

import styles from './ShippingPriceCalculator.module.css'

const ShippingPriceCalculator = () => {
  const { t } = useTranslation('common', { keyPrefix: 'main' })
  const { routes, selectedRoute, setSelectedRoute } = useSelectedShippingRoute()

  const [fromCountries, toCountries] = useTranslatedShippingRoutes(
    routes,
    selectedRoute?.route.fromCountry,
  )

  const [isToCountryManuallyChanged, setIsToCountryManuallyChanged] = useState(false)

  const handleFromCountryChange = (selectedFromCountry: SupportedCountry) => {
    if (!selectedRoute || !routes) return

    const routeWithChangedFromCountry = findRouteIn(routes, {
      fromCountry: selectedFromCountry,
      toCountry: selectedRoute.route.toCountry,
    })

    if (isToCountryManuallyChanged && routeWithChangedFromCountry) {
      setSelectedRoute(routeWithChangedFromCountry)

      return
    }

    const domesticRoute = findRouteIn(routes, {
      fromCountry: selectedFromCountry,
      toCountry: selectedFromCountry,
    })
    const fallbackRoute = getFirstRouteFrom(selectedFromCountry, routes)

    const route = domesticRoute || fallbackRoute

    if (!route) return

    setSelectedRoute(route)
  }

  const handleToCountryChange = (selectedToCountry: SupportedCountry) => {
    if (!selectedRoute) return

    setIsToCountryManuallyChanged(true)

    const route = findRouteIn(routes, {
      fromCountry: selectedRoute.route.fromCountry,
      toCountry: selectedToCountry,
    })

    if (!route) return

    setSelectedRoute(route)
  }

  const renderDropdownTitle = (title: string) => {
    return (
      <>
        <span className={styles.dropdownTitleMobile}>
          <Text theme="inverse" type={Text.Type.Subtitle} text={title} />
        </span>
        <span className={styles.dropdownTitleDesktop}>
          <Text type={Text.Type.Subtitle} text={title} />
        </span>
      </>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.synopsisMobile}>
        <div className={styles.title}>
          <Text
            html
            theme="inverse"
            type={Text.Type.HeadingXXL}
            text={t('shippingCTA.title')}
            alignment={Text.Alignment.Left}
          />
        </div>
        <Spacer size={Spacer.Size.Medium} />
        <Text
          html
          theme="inverse"
          type={Text.Type.Body}
          text={t('shippingCTA.description')}
          alignment={Text.Alignment.Left}
        />
        <Spacer size={Spacer.Size.Large} />
      </div>
      <div className={styles.synopsisDesktop}>
        <div className={styles.title}>
          <Text
            html
            type={Text.Type.HeadingXXL}
            text={t('shippingCTA.title')}
            alignment={Text.Alignment.Left}
          />
        </div>
        <Spacer size={Spacer.Size.Medium} />
        <Text
          html
          type={Text.Type.Body}
          text={t('shippingCTA.description')}
          alignment={Text.Alignment.Left}
        />
        <Spacer size={Spacer.Size.Large} />
      </div>

      <DropdownSelect<SupportedCountry>
        name="fromCountry"
        options={fromCountries}
        value={selectedRoute?.route.fromCountry}
        placeholder={t('shippingCTA.priceCalculator.placeholders.country') || ''}
        title={renderDropdownTitle(t('shippingCTA.priceCalculator.fromCountry'))}
        onOptionSelect={handleFromCountryChange}
      />

      <Spacer size={Spacer.Size.Medium} />

      <DropdownSelect<SupportedCountry>
        name="toCountry"
        options={toCountries}
        value={selectedRoute?.route.toCountry}
        placeholder={t('shippingCTA.priceCalculator.placeholders.country') || ''}
        title={renderDropdownTitle(t('shippingCTA.priceCalculator.toCountry'))}
        onOptionSelect={handleToCountryChange}
      />

      <Spacer size={Spacer.Size.X2Large} />

      <div className={styles.cta}>
        <BuyLabelButton selectedRoute={selectedRoute?.route} />
      </div>
    </div>
  )
}

export default ShippingPriceCalculator
