import { useMemo, useState, useContext, createContext, ReactNode, useEffect } from 'react'

import { Coverage } from 'lib/types/coverage'
import { usePrefilledRoute } from 'lib/hooks/usePrefilledRoute'
import { RouteModel, RoutesModel } from 'lib/types/models/route'

import { getCoveragePriceLimits } from './utils'

type ShippingRouteContextState = {
  selectedRoute?: {
    coverage: Coverage
    route: RouteModel
  }
  routes: RoutesModel | null
  setSelectedRoute: (route: RouteModel) => void
}

const INITIAL_SHIPPING_ROUTE_CONTEXT_STATE: ShippingRouteContextState = {
  routes: null,
  /* eslint-disable @typescript-eslint/no-empty-function */
  setSelectedRoute: () => {},
}

const ShippingRouteContext = createContext<ShippingRouteContextState>(
  INITIAL_SHIPPING_ROUTE_CONTEXT_STATE,
)

const ShippingRouteProvider = ({
  routes,
  children,
  initialSelectedRoute,
}: {
  children: ReactNode
  routes: RoutesModel | null
  initialSelectedRoute: RouteModel | null
}) => {
  const prefilledRoute = usePrefilledRoute(routes)
  const [selectedRoute, setSelectedRoute] = useState<RouteModel | null>(initialSelectedRoute)

  useEffect(() => {
    if (initialSelectedRoute) {
      setSelectedRoute(initialSelectedRoute)

      return
    }

    if (!prefilledRoute) return

    setSelectedRoute(prefilledRoute)
  }, [prefilledRoute, initialSelectedRoute])

  const value = useMemo<ShippingRouteContextState>(
    () => ({
      routes,
      setSelectedRoute,
      ...(selectedRoute && {
        selectedRoute: {
          route: selectedRoute,
          coverage: getCoveragePriceLimits(selectedRoute.labelPriceFrom),
        },
      }),
    }),
    [routes, selectedRoute],
  )

  return <ShippingRouteContext.Provider value={value}>{children}</ShippingRouteContext.Provider>
}

const useSelectedShippingRoute = () => useContext(ShippingRouteContext)

export { ShippingRouteProvider, useSelectedShippingRoute }
