import { useTranslation } from 'next-i18next'

import { Spacer } from '@vinted/web-ui'

import IllustratedTextGrid from 'components/IllustratedTextGrid'

import styles from './HelpSection.module.css'

const HelpSection = () => {
  const { t } = useTranslation('common', { keyPrefix: 'main.help' })

  return (
    <div className={styles.wrapper}>
      <Spacer size={Spacer.Size.X4Large} />
      <div className={styles.content}>
        <IllustratedTextGrid
          items={[
            {
              title: t('title'),
              description: t('description'),
              imageSrc: '/assets/customer-support.png',
              action: {
                title: t('action'),
                href: '/help',
              },
            },
          ]}
        />
      </div>
      <Spacer size={Spacer.Size.X4Large} />
    </div>
  )
}

export default HelpSection
