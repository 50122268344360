import { Money } from 'lib/types/money'
import { Coverage } from 'lib/types/coverage'
import { EXTRA_COVERAGE_AMOUNT, STANDARD_COVERAGE_AMOUNT } from 'lib/constants/pricing'

export const getCoveragePriceLimits = ({ currency }: Money): Coverage => {
  return {
    standardCoverageLimit: STANDARD_COVERAGE_AMOUNT[currency],
    extraCoverageLimit: EXTRA_COVERAGE_AMOUNT[currency],
  }
}
