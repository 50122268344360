import { ReactNode } from 'react'
import { GetServerSideProps, GetServerSidePropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Layout from 'components/Layout'

import { RouteModel, RoutesModel } from 'lib/types/models/route'
import { transformRoutesDto } from 'lib/types/transformers/route'
import { logGenericFetchServerError } from 'lib/utils/logger-server'
import { findRouteIn, getRouteFromRequestHeaders } from 'lib/utils/route'

import { getRetailAvailableRoutesApiHandler } from 'api/available_routes/handler'

import MainPage from 'modules/MainPage'

type Props = {
  routes: RoutesModel | null
  selectedRoute: RouteModel | null
}

const App = ({ routes, selectedRoute }: Props) => {
  return <MainPage routes={routes} selectedRoute={selectedRoute} />
}

const getRetailAvailableRoutes = async (
  req: GetServerSidePropsContext['req'],
  res: GetServerSidePropsContext['res'],
) => {
  const response = await getRetailAvailableRoutesApiHandler(req, res)

  if ('errors' in response) {
    logGenericFetchServerError({
      feature: 'index_page_available_routes',
      errorLabel: 'index page | available_routes fetch error',
      responseWithError: response,
    })

    return null
  }

  return transformRoutesDto(response.payload)
}

export const getServerSideProps: GetServerSideProps<Props> = async ({ req, res, locale }) => {
  const routeQuery = getRouteFromRequestHeaders(req.headers)
  const routes = await getRetailAvailableRoutes(req, res)
  const selectedRoute = findRouteIn(routes, routeQuery)

  return {
    props: {
      routes,
      selectedRoute,
      ...(await serverSideTranslations(locale || 'en', ['common'])),
    },
  }
}

App.getLayout = (page: ReactNode) => {
  return <Layout contentWidth="fullScreen">{page}</Layout>
}

export default App
