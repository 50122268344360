import Image from 'next/image'

import { Text, Spacer } from '@vinted/web-ui'

import { getStaticAssetUrl } from 'lib/utils/image'

import styles from './Proposition.module.css'

const PROPOSITION_IMAGE_PATH_PREFIX = 'value-proposition'

type Props = {
  title: string
  description: string
  src: `${string}.svg`
}

const Proposition = ({ src, title, description }: Props) => {
  return (
    <div id={src} key={src} className={styles.proposition}>
      <div className={styles.propositionImage}>
        <Image
          alt=""
          width="140"
          height="100"
          loading="lazy"
          src={getStaticAssetUrl(`${PROPOSITION_IMAGE_PATH_PREFIX}/${src}`)}
        />
      </div>
      <Spacer size={Spacer.Size.Large} />
      <div className={styles.propositionTitle}>
        <Text text={title} theme="amplified" alignment={Text.Alignment.Center} />
      </div>
      <Spacer />
      <div className={styles.propositionContent}>
        <Text text={description} alignment={Text.Alignment.Center} />
      </div>
    </div>
  )
}

export default Proposition
