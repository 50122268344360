import { useTranslation } from 'next-i18next'

import { Spacer, Text } from '@vinted/web-ui'

import PartnerLogo from './PartnerLogo'

import styles from './TrustedPartnersSection.module.css'

const TrustedPartnersSection = () => {
  const { t } = useTranslation('common', { keyPrefix: 'main.trustedPartners' })

  return (
    <div className={styles.wrapper}>
      <Spacer size={Spacer.Size.X4Large} />
      <div className={styles.content}>
        <div className={styles.description}>
          <Text
            text={t('title')}
            width={Text.Width.Parent}
            type={Text.Type.HeadingXXL}
            alignment={Text.Alignment.Center}
          />
          <Spacer size={Spacer.Size.Medium} />
          <Text
            text={t('description')}
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
          />
        </div>

        <Spacer size={Spacer.Size.X2Large} />

        <div className={styles.logoList}>
          <PartnerLogo width="90" height="30" alt="Mondial Relay" src="mondial-relay.svg" />
          <PartnerLogo width="139" height="25" alt="Relais Colis" src="relais-colis.svg" />
          <PartnerLogo width="109" height="14" alt="Poste Italiane" src="poste-italiane.svg" />
          <PartnerLogo width="40" height="40" alt="Colis Privé" src="colis-prive.svg" />
          <PartnerLogo width="37" height="36" alt="Correos" src="correos.svg" />
          <PartnerLogo width="81" height="48" alt="InPost" src="inpost.svg" />
          <PartnerLogo width="135" height="48" alt="Homerr" src="homerr.svg" />
          <PartnerLogo width="88" height="24" alt="Yodel" src="yodel.svg" />
          <PartnerLogo width="106" height="34" alt="Vinted Go" src="vintedgo.svg" />
          <PartnerLogo width="48" height="31" alt="CTT" src="ctt.svg" />
          <PartnerLogo width="55" height="39" alt="Bpost" src="bpost.svg" />
          <PartnerLogo width="105" height="30" alt="SEUR" src="seur.svg" />
        </div>
      </div>
      <Spacer size={Spacer.Size.X4Large} />
    </div>
  )
}

export default TrustedPartnersSection
