import { useRef, useState, useEffect, RefObject } from 'react'

type IntersectionObserverOptions = {
  root?: Element | null
  rootMargin?: string
  threshold?: number | Array<number>
}

const useIntersectionObserverOnce = (
  target: RefObject<Element>,
  options: IntersectionObserverOptions = {},
): boolean => {
  const [isVisible, setIsVisible] = useState(false)
  const observerRef = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    const { root, rootMargin, threshold } = options

    const observerCallback: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) return

        setIsVisible(true)
        observerRef.current?.disconnect()
      })
    }

    observerRef.current = new IntersectionObserver(observerCallback, {
      root,
      rootMargin,
      threshold,
    })

    if (target.current) {
      observerRef.current.observe(target.current)
    }

    return () => observerRef.current?.disconnect()
  }, [target, options])

  return isVisible
}

export default useIntersectionObserverOnce
