import { useMemo } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { Text, Button } from '@vinted/web-ui'

import { toUrlQuery } from 'lib/utils/url'
import { formatMoney } from 'lib/utils/currency'
import type { RouteModel } from 'lib/types/models/route'

import styles from './BuyLabelButton.module.css'

const baseCheckoutUrl = '/shipping'

type Props = {
  selectedRoute?: RouteModel
}

const BuyLabelButton = ({ selectedRoute }: Props) => {
  const { locale } = useRouter()
  const { t } = useTranslation('common', { keyPrefix: 'main' })

  const buttomTitle = useMemo(() => {
    const formattedPrice = selectedRoute ? formatMoney(selectedRoute.labelPriceFrom, locale) : null

    if (!formattedPrice) return t('shippingCTA.actions.buyLabel')

    return `${t('shippingCTA.actions.buyLabelWithPrice')} ${formattedPrice}`
  }, [t, locale, selectedRoute])

  const checkoutUrl = useMemo(() => {
    if (!selectedRoute) return baseCheckoutUrl

    return `${baseCheckoutUrl}?${toUrlQuery({
      from_country: selectedRoute.fromCountry,
      to_country: selectedRoute.toCountry,
    })}`
  }, [selectedRoute])

  return (
    <div className={styles.buyLabelButton}>
      <Link prefetch={false} href={checkoutUrl} data-testid="buy-label-cta-link-wrapper">
        <Button
          theme="expose"
          styling={Button.Styling.Filled}
          text={<Text type={Text.Type.Title} text={buttomTitle} />}
          testId="buy-label-cta-button"
        />
      </Link>
    </div>
  )
}

export default BuyLabelButton
