import { ComponentProps } from 'react'
import { Text, Cell, Image } from '@vinted/web-ui'

import styles from './PageTitleWithImage.module.css'

type Props = {
  textType?: ComponentProps<typeof Text>['type']
  text: ComponentProps<typeof Text>['text']
  image: 'arrow-forward' | 'curvy-line'
}

const PageTitleWithImage = ({ text, image, textType = Text.Type.HeadingXXL }: Props) => {
  return (
    <Cell styling={Cell.Styling.Tight} theme="transparent">
      <Text
        text={text}
        type={textType}
        width={Text.Width.Parent}
        alignment={Text.Alignment.Center}
      />
      <div className={styles.arrow}>
        <Image src={`/assets/svg/${image}.svg`} alt="" />
      </div>
    </Cell>
  )
}

export default PageTitleWithImage
