import { ImgHTMLAttributes } from 'react'
import Link from 'next/link'
import classNames from 'classnames'

import { Text, Image, Spacer, Button } from '@vinted/web-ui'

import styles from './GridItem.module.css'

type ActionProps = {
  title: string
  href: string
}

type Props = {
  action?: ActionProps
  title?: string | null
  isTextOnTheRight?: boolean
  isTextExtendedPaddingApplied?: boolean
  imageLoadingStrategy?: ImgHTMLAttributes<HTMLImageElement>['loading']
  imageSrc: string
  description: string
}

const GridItem = ({
  title,
  action,
  imageSrc,
  description,
  isTextOnTheRight = false,
  imageLoadingStrategy = 'lazy',
  isTextExtendedPaddingApplied = false,
}: Props) => {
  const containerClass = classNames(styles.gridItemContainer, {
    [styles.reverse]: !isTextOnTheRight,
  })

  const regularPadding = isTextOnTheRight ? styles.textPaddingLeft : styles.textPaddingRight
  const extendedPadding = isTextOnTheRight
    ? styles.textExtendedPaddingLeft
    : styles.textExtendedPaddingRight
  const appliedPadding = isTextExtendedPaddingApplied ? extendedPadding : regularPadding

  const textClass = classNames(styles.text, appliedPadding)

  return (
    <div className={containerClass}>
      <Image alt="" src={imageSrc} loading={imageLoadingStrategy} />
      <div>
        <div className={textClass}>
          {!!title && (
            <>
              <Text type={Text.Type.HeadingXL} text={title} />
              <Spacer size={Spacer.Size.XLarge} />
            </>
          )}

          <Text html type={Text.Type.Body} text={description} />

          {action ? (
            <>
              <Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.X2Large} />
              <div className={styles.actionButton}>
                <Link prefetch={false} href={action.href}>
                  <Button
                    inline
                    text={action.title}
                    theme="primary"
                    styling={Button.Styling.Outlined}
                  />
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default GridItem
