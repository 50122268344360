import { useTranslation } from 'next-i18next'

import { Text, Spacer, Divider } from '@vinted/web-ui'

import IllustratedTextGrid from 'components/IllustratedTextGrid'

import styles from './PriceAndSustainabilitySection.module.css'

const PriceAndSustainabilitySection = () => {
  const { t } = useTranslation('common', { keyPrefix: 'main.priceAndSustainability' })

  return (
    <div className={styles.wrapper}>
      <Spacer size={Spacer.Size.X4Large} />

      <div className={styles.content}>
        <IllustratedTextGrid
          items={[
            {
              isTextOnTheRight: true,
              title: t('title'),
              description: t('description'),
              imageSrc: '/assets/cheap-sustainable.png',
            },
          ]}
        />
        <Spacer size={Spacer.Size.X4Large} />
        <Divider />
        <Spacer size={Spacer.Size.X4Large} />
        <div className={styles.note}>
          <Text html type={Text.Type.Body} text={t('note')} />
        </div>
      </div>

      <Spacer size={Spacer.Size.X4Large} />
    </div>
  )
}

export default PriceAndSustainabilitySection
