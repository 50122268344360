import { useRef } from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'

import { Text, Spacer } from '@vinted/web-ui'

import { NETWORK_NUMBERS } from 'lib/data/landing'
import { getStaticAssetUrl } from 'lib/utils/image'
import BuyLabelButton from 'components/BuyLabelButton'
import useIntersectionObserverOnce from 'lib/hooks/useIntersectionObserverOnce'

import NumberCountUp from './NumberCountUp'

import styles from './NetworkNumbersSection.module.css'

const MAP_ASSET_URL = getStaticAssetUrl('network-map.png')

const NetworkNumbersSection = () => {
  const { t } = useTranslation('common', { keyPrefix: 'main.networkNumbers' })

  const networkNumbersRefTablet = useRef<HTMLDivElement | null>(null)
  const isNetworkNumbersIntersectingTablet = useIntersectionObserverOnce(networkNumbersRefTablet)

  const renderCountUps = (isIntersecting: boolean) => {
    return (
      <>
        <NumberCountUp
          finalNumber={NETWORK_NUMBERS.pickUpPoints}
          startCountUp={isIntersecting}
          caption={t('pickUpPoints')}
        />
        <NumberCountUp
          finalNumber={NETWORK_NUMBERS.routes}
          startCountUp={isIntersecting}
          caption={t('routes')}
        />
        <NumberCountUp
          finalNumber={NETWORK_NUMBERS.annualShipmentsMillion}
          startCountUp={isIntersecting}
          caption={t('annualShipments')}
          postfix={t('million')}
        />
      </>
    )
  }

  return (
    <div className={styles.wrapper}>
      <Spacer size={Spacer.Size.X4Large} />
      <div className={styles.content}>
        <div className={styles.cta}>
          <div className={styles.titleDesktop}>
            <Text text={t('title')} type={Text.Type.HeadingXXL} theme="inverse" />
          </div>
          <Spacer size={Spacer.Size.X2Large} />
          <div ref={networkNumbersRefTablet} className={styles.networkNumbers}>
            {renderCountUps(isNetworkNumbersIntersectingTablet)}
          </div>
          <Spacer size={Spacer.Size.X2Large} />
          <div className={styles.buyLabelButton}>
            <BuyLabelButton />
          </div>
        </div>

        <div className={styles.map}>
          <Image
            alt=""
            width="567"
            height="502"
            loading="lazy"
            src={MAP_ASSET_URL}
            sizes="(max-width: 640px) 330px, 567px"
            style={{ objectFit: 'scale-down' }}
          />
        </div>

        <div className={styles.titleMobile}>
          <Text
            text={t('title')}
            theme="inverse"
            width={Text.Width.Parent}
            type={Text.Type.HeadingXXL}
            alignment={Text.Alignment.Center}
          />
          <Spacer size={Spacer.Size.X3Large} />
        </div>
      </div>
      <Spacer size={Spacer.Size.X4Large} />
    </div>
  )
}

export default NetworkNumbersSection
